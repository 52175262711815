.footer {
    background-color: var(--eerie-black);
    padding: 30px 34px;
    width: var(--max-width);
    max-width: 90vw;
}

.footer-terms {
    color: var(--white);
    font-family: var(--font-family-mont-book);
    font-size: var(--font-size-xs);
    font-weight: 400;
    letter-spacing: 0;
    margin-left: 26.84px;
    margin-top: 17px;
    min-height: 19px;
    width: 385px;
}

.footer-flex {
    display: flex;
    flex-flow: row wrap;
    column-gap: 40px;
}

.secondary-white-logo {
    height: 70px;
    object-fit: cover;
    width: 166px;
}

.footer-left-info {
    display: flex;
    margin-top: 26px;
}

.footer-left-info-contact {
    letter-spacing: 0;
    width: 75px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.foot-right-column {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 60px;
}

.footer-right-operation-social {
    align-items: center;
    display: flex;
    column-gap: 20px;
    padding: 40px 20px;
}

.footer-right-operation-icon {
    height: auto;
    object-fit: cover;
    width: 33px;
    cursor: pointer;
}

.footer-divider {
    margin-left: 3.99px;
    margin-top: 3px;
    width: 1337px;
}

.footer-hubspot {
    width: 200px;
    height: 300px;
    margin-top: 60px;
}