a {
    cursor: pointer;
}

html {
    scroll-behavior: smooth;
}

/* Overall Resource */
.resource-bg {
    background-image: url(/images/resource_bg.png);
    background-size: cover;
    padding: 160px 80px;
    line-height: 1.2;
    height: 630px;
}

.res-title {
    width: 1000px;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
}

.resource-option-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.resource-option-item {
    cursor: pointer;
    text-align: center;
    padding-top: 24px;
    width: 300px;
    height: 72px;
    background: transparent;
    transform: perspective(10px) rotateX(1deg);
    margin: 10px;
    border: 4px solid var(--blue);
    border-bottom: 4px solid var(--pizazz);
    font-size: 23px;
}

.resource-option-selected {
    border: 4px solid var(--pizazz);
    border-bottom: 0px;
}

.resource-option-edge {
    height: 84px;
    width: calc((100% - 1280px)/2);
    background: transparent;
    border: 0px;
    border-bottom: 4px solid var(--pizazz);
}

.resource-download {
    text-align: center;
    background-color: var(--denim);
    border-radius: 30px;
    display: block;
    height: 50px;
    width: 180px;
    padding: 14px 14px;
    letter-spacing: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}


/* Resource Marketing*/
.res-marketing {
    padding: 20px 0px;
}

.marketing-option-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    column-gap: 30px;
    row-gap: 20px;
}

.marketing-ppt {
    padding: 100px 50px;
}

.marketing-ppt-flex {
    display: flex;
    flex-flow: row wrap;
    column-gap: 100px;
    row-gap: 40px;
    margin-top: 40px;
    justify-content: center;
}

.marketing-ppt-item {
    height: 500px;
}

.marketing-catalogue {
    padding: 50px 100px;
    background-image: url(/images/resource-catalogue-bg.png);
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 40px;
    min-height: 700px;
}

.marketing-video {
    padding: 100px 10px;
}

.marketing-video-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    row-gap: 40px;
    column-gap: 60px;
    margin-top: 40px;
}

.marketing-video-item {
    height: 400px;
}

.marketing-webinar {
    padding: 100px 10px;
}

.marketing-webinar-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    row-gap: 40px;
    column-gap: 50px;
    margin-top: 40px;
}

.marketing-webinar-item {
    background-color: var(--thunder);
    width: 415px;
    height: 900px;
}

.marketing-webinar-item-text {
    padding: 50px 40px;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 20px;
    line-height: 1.3;
    height: 580px;
}

.marketing-FAQ {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 60px;
    padding: 20px 80px;
}

.marketing-FQA-subItem {
    margin-bottom: 20px;
    position: relative;
}

.marketing-FAQ-answer {
    padding: 20px 50px;
    line-height: 1.4;
}


/* Shapes */
.arrow {
    position: absolute;
    right: 10px;
    display: inline-block;
    height: 0;
    margin-right: 10px;
    width: 0;
    cursor: pointer;
}

.arrow-down {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 25px solid var(--denim);
}

.arrow-left {
    bottom: 0;
    right: 20px;
    border-bottom: 20px solid transparent;
    border-right: 25px solid var(--denim);
    border-top: 25px solid transparent;
}


/* Resource News*/
.res-news {
    padding: 80px 100px;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 60px;
}

.res-news-flex {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 20px;
    line-height: 1.4;
    position: relative;
}

.res-news-page-flex {
    display: flex;
    flex-flow: row wrap;
    column-gap: 20px;
    justify-content: center;
}

.res-news-pagenum {
    cursor: pointer;
    width: 50px;
    text-align: center;
    border: 4px solid transparent;
}


/* Resource Industry */
.res-industry {
    padding: 60px 0px;
}

.res-industry-flex {
    display: flex;
    flex-flow: row wrap;
    margin-top: 50px;
    column-gap: 40px;
    row-gap: 80px;
    justify-content: center;
}

.res-industry-item {
    background-color: var(--thunder);
    width: 330px;
    height: 400px;
    cursor: pointer;
}

.res-industry-itemText {
    padding: 40px 20px;
    padding-right: 80px;
    display: flex;
    row-gap: 20px;
    flex-flow: column nowrap;
    line-height: 1.3;
}

/* Resource Press */
.res-press {
    padding: 60px 50px;
}

.res-press-item {
    background-color: var(--thunder);
    width: 400px;
    height: 560px;
    cursor: pointer;
    transition: 300ms;
    max-width: 90vw;
    position: relative;
}

.res-press-item:hover {
    margin-top: -20px;
    box-shadow: 5px -20px 70px 15px rgb(61, 133, 228);
    height: 580px;
}